:root {
  font-family: sans-serif;
}

main {
}

img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

article {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 6px;
  padding: 2em;
  margin-top: 2em;
}

code {
  font-size: 1.125rem;
  font-family: monospace;
}

body {
  margin: 0;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

footer {
  text-align: center;
  font-size: 0.85em;
  padding: 2em;
}

p {
  margin: 0;
}